<template>
  <div class="UserConnectBar">
    <table>
      <tr>
        <td><CircleButton :label="$locale['message']" icon="comment" size="49px" background="transparent" @click="sendThing('message')" /></td>
      </tr>
      <tr>
        <td><CircleButton :label="$locale['gift']" icon="gift" size="49px" background="transparent" @click="sendThing('gift')" /></td>
      </tr>
      <tr>
        <td><CircleButton :label="$locale['flowers']" icon="flower" size="49px" background="transparent" @click="sendThing('flowers')" /></td>
      </tr>
      <tr>
        <td><CircleButton :label="$locale['tip']" icon="dollar-circle" size="49px" background="transparent" @click="sendThing('tip')" /></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  methods: {
    sendThing: function(thing) {
      this.post = {
        user: this.$creatorFeed,
      };

      if (thing === "message") {
        this.userMessages(this.post);
      } else {
        this.goInsentive(thing);
      }

      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.UserConnectBar {
  text-align: center;
}
</style>
